import { Injectable } from '@angular/core';
import { CATEGORY_FILTERS_OPTIONS, Filter } from '@scalefast/core';
import { StoreSearchService } from '@scalefast/ecommerce-core';

@Injectable({
  providedIn: 'root',
})
export class CustomStoreSearchService extends StoreSearchService {
  setDefaultFilters(): void {
    const defaultCategoryFilters: Filter[] = this.getDefaultFiltersFromFiltersConfiguration();
    const currentCategoryFilters: Filter[] = this.getFilterSelected()?.filters;
    let newFilters: Filter[] = [];
    //Iterate all current filters and set back with same value as default filter or none (to be deleted) if it is not contained on default filters.
    currentCategoryFilters.map((currentCategoryFilter) => {
      const defaultValueForCurrentFilter = defaultCategoryFilters.find(
        (defaultCategoryFilter) => defaultCategoryFilter.name === currentCategoryFilter.name,
      )?.values;
      newFilters.push({
        name: currentCategoryFilter.name,
        values: defaultValueForCurrentFilter ? defaultValueForCurrentFilter : [],
        groupBy: currentCategoryFilter.groupBy,
      });
    });
    //set all remaining default filters
    newFilters = newFilters.concat(
      defaultCategoryFilters.filter(
        (defaultCategoryFilter) =>
          !currentCategoryFilters.find((currentCategoryFilter) => currentCategoryFilter.name === defaultCategoryFilter.name),
      ),
    );
    this.setFilters({
      offset: CATEGORY_FILTERS_OPTIONS.DEFAULT_PAGE_OFFSET,
      pageSize: CATEGORY_FILTERS_OPTIONS.DEFAULT_PAGE_SIZE,
      filters: newFilters,
    });
  }
}
